import React, { Component } from 'react';
import Countdown from './Countdown';
import { Navbar, Container, Col } from 'react-bootstrap';

class AppBook extends Component {
  render() {
    return (

      <Navbar expand="lg" variant="light" fixed="top" className="purple">
        <Container fluid>
          
            <Col lg={6} xs={12}>
              <div className="text-App">
                <p>Subsidi Ongkir 11ribu Hanya Hari Ini</p>
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="App">
                <Countdown date={`April 14, 2020 22:00:00`} />
              </div>
            </Col>
        </Container>
      </Navbar>
      
    );
  }
}

export default AppBook;